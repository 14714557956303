// fonts
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');

//fontawesome
@import '../node_modules/@fortawesome/fontawesome-pro/css/all.css';

@import 'variables';

//Bulma
@import '../node_modules/bulma/bulma.sass';

//helpers
@import "helpers/flex";
@import "helpers/spacing";
@import "helpers/wysiwyg-content";
@import "helpers/border_radius";
@import "helpers/buttons";
@import "helpers/animate";

//partials
@import "partials/header";
@import "partials/footer";
@import "partials/subscribe";
@import "partials/download-assets-block";
@import "partials/card-recommendation";
@import "partials/card-news";
@import "partials/cookiebar";

//templates
@import "home";
@import "recommendations";
@import "news";
@import "agenda";
@import "over";
@import "contact";
@import "search";

html, body{
	height: 100%;
}

main {
	min-height: calc(100vh - 296px);
}

div.has-sub{
	position: relative;
	z-index: 0;
}
.agenda-index {
	.agenda-item-date-header {
		width: 128px
	}
	.agenda-item-time-header {
		width: 6rem;
	}
	.agenda-empty-state {
		border-bottom: 1px solid $primary-light;
		border-top: 1px solid $primary-light;
	}
	.agenda-item-container:first-of-type {
		border-top: 1px solid $primary-light;
	}
	.agenda-item-container {
		border-bottom: 1px solid $primary-light;
		padding: 2px 0;
		color: $text;
		transition: background-color 1s ease;
		&:hover {
			background-color: lighten($primary-lighter, 6%);
		}
		.agenda-item {
			flex: 1;

			.agenda-item-date {
				line-height: 25px;
				color: $white;
				@include tablet() {
					width: 128px;
				}
			}
			.agenda-item-time {
				width: 6rem;
			}
			.agenda-item-title {
				flex: 1;
			}
		}
	}
}

.agenda-show {
	.header-image {
		background-repeat: no-repeat;
		background-size: cover;
		padding: 80px 0;
		height: 380px;
		text-shadow: 1px 1px 1px darkgrey;
		@include tablet() {
			height: 536px;
			padding: 112px 0;
		}
	}
	.register-block {
		p {
			font-size: 16px;
		}
	}
}
.recommendations{

	&.ongoing {

		/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,f1f1f1+50,e1e1e1+51,f6f6f6+100;White+Gloss+%231 */
		background: #E9F6FC; /* Old browsers */
		background: -moz-linear-gradient(-45deg,  #E9F6FC 0%, #E9F6FC 55%, #D4F3FF 55%, #D4F3FF 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(-45deg,  #E9F6FC 0%,#E9F6FC 55%,#D4F3FF 55%,#D4F3FF 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(135deg,  #E9F6FC 0%,#E9F6FC 55%,#D4F3FF 55%,#D4F3FF 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

		.column {
			&.recommendation-item {
				> div {
					min-height: 450px;
				}
			}
		}

	}

	.column {
		&.recommendation-item {
			min-height: 400px;
			&.not-in-search-result {
				display: none !important;
			}
		}
	}
	.is-flex-hidden {
		display:none;
	}

	.filter {

		margin-left: -1rem;
		margin-bottom: -1rem;
		.filter-item {
			display: inline-block;
			padding: .5rem 2rem;
			margin-left: 1rem;
			margin-bottom: 1rem;
			background-color: $light-grey;
			transition: background-color 500ms ease;
			&:hover {
				cursor: pointer;
				background-color: darken($light-grey, 6%);
			}
			&.active {
				background-color: $primary-lighter;

			}
		}
	}
}
.recommendation-show {
	.header-image {
		background-repeat: no-repeat;
		background-size: cover;
		height: 200px;
		@include tablet() {
			height: 288px;
		}
	}

	.card {
		position: relative;
		border-radius: 5px;
		background: #D4F3FF;
		text-align: center;
		min-height: 300px;

		.button {
			font-size: 16px;
			border-radius: 4px;
			padding: 0.5em;
		}

		&.secretary {
			background: #9BDCF6;
		}

		img {
			border-radius: 100px;
		}
	}

	.related-news-item {

	}

	.download-assets-block {
		p {
			font-size: 1.5rem;
		}
	}

	.agenda-item-date-header {
		width: 128px
	}
	.agenda-item-time-header {
		width: 48px
	}
	.agenda-empty-state {
		border-bottom: 1px solid $primary-light;
		border-top: 1px solid $primary-light;
	}
	.agenda-item-container:first-of-type {
		border-top: 1px solid $primary-light;
	}
	.agenda-item-container {
		border-bottom: 1px solid $primary-light;
		padding: 2px 0;
		color: $text;
		transition: background-color 500ms ease;
		&:hover {
			background-color: lighten($primary-lighter, 6%);
		}
		.agenda-item {
			flex: 1;

			.agenda-item-date {
				line-height: 25px;
				color: $white;
				@include tablet() {
					width: 128px;
				}
			}
			.agenda-item-time {

			}
			.agenda-item-title {
				flex: 1;
			}
		}
	}
}
hiddendiv {
	display: none;
}
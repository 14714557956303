.news-item {
	display: flex;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	min-height: 296px;
	width: 100%;
	.date-range {
		font-size: 16px;
	}

	a {
		font-size: 16px;
	}

	.dark-overlay {
		flex-grow: 1;
		color: white;
		background-color: rgba(0, 0, 0, 0.3);
		width: 100%;
	}
}
footer {
    p {
      	opacity: 0.7;
    }

	a {
		display: inline-block;
		margin: 0 10px 0 0;
	  	color: black;
	  	opacity: 0.7;

		:hover {
			color: black;
			opacity: 0.8;
		}
	}

  	.underline {
		//text-decoration: underline;

		border-bottom: 1px solid #000;
  	}
}

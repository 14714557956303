header {
	nav {
		height: 116px;

		.nav-brand-item {
			height: 134px;
			width: 232px;
			display: flex;
			justify-content: center;
			z-index: 2;
			padding: 0 16px 0 16px;
			margin: 0 16px -16px 0;
			&.is-active {
				background-image: url("..//img/header-item-active.svg");
				background-repeat: no-repeat;
				color: #FFFFFF;
				background-size: 100% 100%;
			}
		}

		.nav-menu {
			display: none;
			@include from($desktop) {
				display: flex;
			}
		}

		.nav-item {
			height: 134px;
			min-width: 103px;
			display: flex;
			justify-content: center;
			align-items: flex-end;
			padding: 0 8px 38px 8px;
			margin: 0 4px -16px 4px;

			font-size: 20px;
			line-height: 27px;
			font-weight: 600;
			color: $text;

			&:hover {
				color: #FFFFFF;
			}

			&.is-active {
				background-image: url("../img/header-item-active.svg");
				background-repeat: no-repeat;
				color: #FFFFFF;
				background-size: 100% 100%;
			}
		}

		.search-wrapper {
			padding-left: 0;
			padding-bottom: 24px;
			@include from($desktop) {
				padding-bottom: 16px;
				padding-left: 16px;
			}

			p {
				position: relative;

				input {
					padding: 4px 32px 4px 8px;
					font-size: 20px;
					width: 184px;
					height: 40px;
					border-radius: 8px;
					border: none;
				}

				button {
					position: absolute;
					right: 8px;
					top: 8px;
					padding: 0;
					margin: 0;
					border: none;
					font-size: 18px;
					background: none;

					&:hover {
						cursor: pointer;
					}

				}
			}
		}

		.mobile-nav-button-container {
			height: 100%;
			padding: 32px;
			display: flex;
			align-items: center;
			@include from($desktop) {
				display: none;
			}

			.mobile-nav-button {
				margin: 0 0 0 0;
				width: 25px;
				height: 25px;

				-webkit-transform: rotate(0deg);
				-moz-transform: rotate(0deg);
				-o-transform: rotate(0deg);
				transform: rotate(0deg);
				cursor: pointer;

				span {
					display: block;
					position: absolute;
					height: 3px;
					width: 100%;
					background: $white;
					border-radius: 8px;
					opacity: 1;
					-webkit-transition: .2s ease-in-out;
					-moz-transition: .2s ease-in-out;
					-o-transition: .2s ease-in-out;
					transition: .2s ease-in-out;

					&:nth-child(1) {
						top: 0;
					}

					&:nth-child(2) {
						top: 8px;

					}

					&:nth-child(3) {
						top: 16px;
					}
				}

				&.open span:nth-child(1) {
					top: 8px;
					-webkit-transform: rotate(135deg);
					-moz-transform: rotate(135deg);
					-o-transform: rotate(135deg);
					transform: rotate(135deg);
				}

				&.open span:nth-child(2) {
					opacity: 0;
				}

				&.open span:nth-child(3) {
					top: 8px;
					-webkit-transform: rotate(-135deg);
					-moz-transform: rotate(-135deg);
					-o-transform: rotate(-135deg);
					transform: rotate(-135deg);
				}
			}
		}

		.mobile-nav-container {
			background-color: $primary-lighter;
			padding: 40px 32px 16px 32px;
			display: none;
			position: fixed;
			left: 0;
			top: 116px;
			width: 100%;
			z-index: 1;

			&.open {
				display: block;
			}

			@include from($desktop) {
				display: none;
				&.open {
					display: none;
				}
			}

			ul {
				list-style: none;
				padding: 0;

				li {
					margin: 0 0 16px 0;
					font-size: 20px;
					display: block;

					a {
						text-decoration: none;
						color: $text;
						font-weight: 400;
						line-height: 24px;

						&:hover {
							color: $primary-dark;
						}

						&.is-active {
							color: $primary-dark;
						}
					}

					&:before {
						content: none;
					}
				}
			}
		}
	}
}
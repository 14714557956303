.homepage {
	.header-image {
		background-repeat: no-repeat;
		background-size: cover;
		padding: 48px 0;
		height: 380px;
		h1 {
			text-shadow: 1px 1px 1px darkgrey;
			font-size: 36px;
		}
		@include tablet() {
			height: 480px;
			padding: 64px 0;
			h1 {
				font-size: 64px;
			}
		}
		@include desktop() {
			height: 490px;
			padding: 64px 0;
			h1 {
				font-size: 72px;
			}
		}
		//@media only screen and (min-width: 1500px) {
		//	height: 536px;
		//	//height: 200px;
		//	padding: 72px 0;
		//	h1 {
		//		font-size: 80px;
		//	}
		//}
	}
	.intro-block {
		background-color: $color-ua-red;
		background-image: url("../img/intro-bg.svg");
		background-repeat: no-repeat;
		color: #FFFFFF;
		background-position: top center;
		background-size: 200% 40%;
		@include tablet() {
			background-size: 150% 40%;
		}
		@include desktop() {
			background-size: 80% 40%;
		}
	}
	.about-block {
		.about-video-container {
			position: relative;
			padding-bottom: 56.25%; /* 16:9 */
			height: 0;

			iframe {
			position: absolute;
			top: 0;
			left: 0;
				width: 100%;
				height: 100%;
			}
		}
	}
	.block-header-wrapper {
		display: block;
		h2 {
			line-height: 1;
		}
		@include tablet() {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
		}
	}
	.recommendations-block {
		.recommendation-item {
			min-height: 464px;
		}
	}
	 .news-block {
		 
	 }
	.agenda-block {
		.agenda-item-date-header {
			width: 128px
		}
		.agenda-item-time-header {
			width: 48px
		}
		.agenda-empty-state {
			border-bottom: 1px solid $primary-light;
			border-top: 1px solid $primary-light;
		}
		.agenda-item-container:first-of-type {
			border-top: 1px solid $primary-light;
		}
		.agenda-item-container {
			border-bottom: 1px solid $primary-light;
			padding: 2px 0;
			color: $text;
			transition: background-color 1s ease;
			&:hover {
				background-color: lighten($primary-lighter, 6%);
			}
			.agenda-item {
				flex: 1;

				.agenda-item-date {
					line-height: 25px;
					color: $white;
					@include tablet() {
						width: 128px;
					}
				}
				.agenda-item-title {
					flex: 1;
				}
			}
		}
	}

}

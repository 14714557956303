.button {
	font-weight: 600;
	border-radius: 8px;
	&.is-primary-dark {
		color: $white;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		&:hover, &:active, &:focus {
			color: $white;
		}
	}
}
// colors
$rich-black: #050505;
$white: #FFFFFF;

$color-isabelline: #F2EFEA;

$color-maximum-blue: #39A9D8;
$color-aero: #78C3E2;
$color-soap: #9BDCF6;
$color-light-cyan: #D4F3FF;

	//Brand SER NN
$color-ua-red: #E40045;
$color-sheen-green: #8CC919;
$color-dark-scarlet: #540D1B;

	// primary shades
$primary: $color-aero;
$primary-dark: $color-maximum-blue;
$primary-darker: darken($primary-dark, 10%) ;
$primary-light: $color-soap;
$primary-lighter: $color-light-cyan;

$light-grey: $color-isabelline;

$custom-colors: (
	"primary-dark":($primary-dark, $primary),
	"primary-light":($primary-light, $primary),
	"primary-lighter":($primary-lighter, $primary),
);

// Body
$body-bg: $white;

// Typography
$font-family-open-sans: "open sans", sans-serif;
$family-sans-serif: $font-family-open-sans;
$body-size: 18px;
$line-height-base: 1.6;
$font-color: $rich-black;

$title-color: $font-color;
$text: $font-color;

$link: $primary-dark;
$link-hover: $primary;

// Button
$button-border-radius: 12px;
$button-padding-horizontal: 1.5rem;

// header
$navbar-background-color: $primary-light;
$navbar-burger-color: $white;
//$navbar-item-active-background-color: $primary;
$navbar-item-active-color: $white;

//footer
$footer-background-color: $primary-light;
$footer-padding: 2rem 1rem 2rem;

.hidden {
	display: none !important;
}
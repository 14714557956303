.news-show {
	.header-image {
		background-repeat: no-repeat;
		background-size: cover;
		padding: 80px 0;
		height: 380px;
		h1 {
			text-shadow: 1px 1px 1px darkgrey;
		}
		@include tablet() {
			height: 536px;
			padding: 112px 0;
		}
	}
}
.news-index {
	.subscribe-block {
		order: 2;
	}
	.column {
		&.news-item-wrapper {
			flex: none;
			width: 100%;
			order: 3;
			@include tablet() {
				width: 33.3333%;
			}
			@include desktop() {
				width: 25%;
			}
			&:nth-of-type(1) {
				order: 1;
				@include desktop() {
					width: 50%;
				}
			}
			&:nth-of-type(6) {
				@include tablet() {
					width: 66.6666%;
				}
				@include desktop() {
					width: 50%;
				}
			}
			&:nth-of-type(9n+15) {
				@include tablet() {
					width: 66.6666%;
				}
				@include desktop() {
					width: 50%;
				}
			}
			&.not-in-search-result {
				display: none !important;
			}
		}
	}
	.is-flex-hidden {
		display:none;
	}

	.filter {
		margin-left: -1rem;
		.filter-item {
			display: inline-block;
			padding: .5rem 2rem;
			margin-left: 1rem;
			background-color: $light-grey;
			transition: background-color 500ms ease;
			&:hover {
				cursor: pointer;
				background-color: darken($light-grey, 6%);
			}

			&.active {
				background-color: $primary-lighter;

			}
		}
	}
}